<template>
  <div>
    <el-breadcrumb style="margin: 0 0 10px 10px" separator="/">
      <el-breadcrumb-item>用户反馈</el-breadcrumb-item>
      <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">反馈列表</div>
        <!-- <div>
          <el-button type="add" icon="el-icon-circle-plus-outline" @click="add"
            >添加</el-button
          >
        </div> -->
      </div>
      <!-- <div class="int_box">
        <div class="int_box11">
          <span>是否处理：</span>
        <el-radio-group v-model="state">
            <el-radio :label="''">全部</el-radio>
            <el-radio :label="1">已处理</el-radio>
            <el-radio :label="2">未处理</el-radio>
        </el-radio-group>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div> -->
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            width="60px"
            type="index"
            label="序号"
          ></el-table-column>
         
          <el-table-column prop="content" label="内容"></el-table-column>
          <el-table-column prop="create_at" label="创建时间"></el-table-column>
          <el-table-column width="80px" label="操作">
            <template v-slot="scope">
              <!-- <el-button
                v-if="scope.row.is_dispose !== 1"
                size="mini"
                type="warning"
                icon="iconfont"
                @click="chuli(scope.row.id)"
                >处理</el-button
              > -->
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="详情"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="success"
                  icon="iconfont icon-chakan-copy"
                  @click="chakan(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 详情 -->
      <el-dialog
        title="详情"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="900px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form ref="form1" :model="addform1" label-width="160px" disabled>
          <!-- <el-form-item label="流水号：">
            <el-input class="int" v-model="addform1.qrcode"></el-input>
          </el-form-item> -->
          <el-form-item label="内容：">
            <el-input
              type="textarea"
              class="int"
              :autosize="{ minRows: 3 }"
              v-model="addform1.content"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="手机号：">
            <el-input class="int" v-model="addform1.mobile"></el-input>
          </el-form-item> -->
          <el-form-item label="提交时间：">
            <el-input class="int" v-model="addform1.create_at"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否处理：">
            <el-input class="int" v-model="addform1.is_dispose"></el-input>
          </el-form-item>
          <el-form-item label="处理时间：">
            <el-input class="int" v-model="addform1.dispose_at"></el-input>
          </el-form-item>
          <el-form-item label="处理人：">
            <el-input class="int" v-model="addform1.username"></el-input>
          </el-form-item> -->
        </el-form>
        <div class="btn_box"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      pageNumber: 10,
      state: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      // 使用配置参数
      dialogVisible1: false,
      addform1: {
        content: '',
        mobile: '',
        create_at: '',
        is_dispose: '',
        dispose_at: '',
        username: '',
        qrcode: '',
      },
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },

    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.addform1 = {
        content: '',
        mobile: '',
        create_at: '',
        is_dispose: '',
        dispose_at: '',
        username: '',
        qrcode: '',
      }
    },

    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/feed/index', {
        params: {
          page: this.page,
          pageNumber: this.pageNumber,
          state: this.state,
        },
      })
      console.log(11, res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },

    // 处理
    chuli(id) {
      this.$confirm('此操作将处理该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post('api/feed/dispose', {
            id: id,
          })
          if (res.code != 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.page = 1
          this.user()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    // 点击查看
    chakan(id) {
      this.dialogVisible1 = true
      this.detail(id)
    },
    // 详情
    async detail(id) {
      const { data: res } = await this.$http.get('api/feed/detail', {
        params: {
          id: id,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.addform1 = {
        content: res.data.content,
        mobile: res.data.mobile,
        create_at: res.data.create_at,
        is_dispose: res.data.is_dispose === 1 ? '已处理' : '未处理',
        dispose_at: res.data.dispose_at,
        username: res.data.username,
        qrcode: res.data.qrcode,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 25px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .int_width {
    width: 300px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
}
</style>
